import React from "react";
import HomeContent from "../Home_Content/Home_Content";


const Pricing= () => {

  return (
    <HomeContent collectionPath='Web/Pricing/Docs' storagePath='Web/Json/Pricing' heading='Pricing'/>
  );
};

export default Pricing; 