// App.js
import React , { useEffect } from 'react';
import Home from './components/Home/Home';

const App = () => {

  const clearLocalStorageOnUnload = () => {
    // Get all local storage keys
    const keys = Object.keys(localStorage);
  
    // Filter keys that contain "cachedFiles"
    const keysToRemove = keys.filter((key) => key.includes('cachedFiles'));
  
    // Remove the matching keys
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  useEffect(() => {
    // Add the event listener
    window.addEventListener('beforeunload', clearLocalStorageOnUnload);

    // Remove the event listener when the component unmounts or when the app is closed
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageOnUnload);
    };
  }, []);

  return (
    <Home/>
  );
};

export default App;
