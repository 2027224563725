import React  from "react";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './Home.css';
import logo from '../../assets/icons/icon-192.png'
import Pricing from "../Pricing/Pricing";
import TermsOfServicePage from '../Terms_of_service/TOS';
import PrivacyPolicyPage from '../Privacy_Policy/Privacy_Policy';
import CancellationPage from '../Cancellation/Cancellation';
import MainContent from "./MainContent";
import Footer from "./Footer";
import Delete from "../Delete/Delete";

const Home = () => {
  return (
    <Router>
    <div>
      <header>
        <div className="header">
          <img src={logo} 
          style={{ width: '50px', height: '50px' }}
          alt="" className="logo" />
          <Link className="title" to="/">Double I</Link>
        </div>
        <div>
          <Link to="/pricing">Pricing</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/cancellation">Cancellation</Link>
          <Link to="/delete-account">Delete Account</Link>
        </div>
      </header>
      <div className="main-content">
      <Routes>
        <Route path="/" element={<MainContent/>} />
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cancellation" element={<CancellationPage />} />
        <Route path="/delete-account" element={ <Delete/>  }/>
      </Routes>
      </div>
      <Footer/>
    </div>
  </Router>
  );
};
  
export default Home;