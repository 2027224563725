import React , {useEffect , useState} from "react";
import './MainContent.css';
import MyRow from "../Objects/MyRow";
import '../Home_Content/Home_Content.css';
import { ClipLoader } from "react-spinners";
import { css } from '@emotion/react';

const MainContent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const cachedData = localStorage.getItem(`cachedFiles_features`);
            if (cachedData) {
              setData(JSON.parse(cachedData));
              setLoading(false);
            } else {
                const response = await fetch('https://firebasestorage.googleapis.com/v0/b/dis-prod-2024.appspot.com/o/Web%2FJson%2FHome%2Fdis_home_page.json?alt=media&token=6be52665-e79b-458d-acce-b383ddac3481');
                const json = await response.json();
                setData(json);  
                localStorage.setItem(`cachedFiles_features`,JSON.stringify(json));
            }        
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    
      },[]);

      const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    return (
      <div>
         <h3 style={{ maxWidth: '100%', textAlign: 'center', color: 'white' }}>
        This App is owned and maintained by {' '}
        <b>DOUBLE I SOLUTIONS PRIVATE LIMITED</b>
      </h3>
        <div className= { 'card-container-vertical' }>
      {loading ? (
        <div className= {'spinner-container-vertical' }>
          <ClipLoader css={override} size={150} color={'#123abc'} loading={loading} />
        </div>
      ) : (
        <div>
          {data.map((item) => (
          <MyRow
            title={item.title}
            imgLink={item.imageLink}
            description={item.description}
            index={item.id}
          />
          ))}
        </div>
      )}
    </div>
      </div>
        
    );
};

export default MainContent;