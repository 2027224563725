import React from 'react';
import './Footer.css';
import Insta from '../../assets/icons/instagram.png';
import Twitter from '../../assets/icons/twitter.png'

const Footer = () => {
    const emailAddress = 'doubleisolutions2023@gmail.com';
  return (
    <div className="footer">
      <div className="contact">
        <h2>Contact Us :</h2>
        <p>Email : <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
        <p>Phone : +91 (934) 617-1974</p>
      </div>
      <div className="centered-text">
        <h2>Reach out to us for customer support</h2>
        <div className="social-icons">
          <a href="https://www.instagram.com/double_i_solutions/?hl=en" target="_blank" rel="noopener noreferrer">
            <img src={Insta} 
             style={{ width: '50px', height: '50px' }}
              alt="App Logo" className="logo" />
          </a>
          <a href="https://twitter.com/doublei2024" target="_blank" rel="noopener noreferrer">
          <img src={Twitter} 
             style={{ width: '50px', height: '50px' }}
              alt="App Logo" className="logo" />
          </a>
          {/* Add more social media icons as needed */}
        </div>
      </div>
      <div className="address">
        <h2>Address : </h2>
        <p>Kondapuram ,</p>
        <p>Nellore Dist ,</p>
        <p>Andhra Pradesh - 524239</p>
      </div>
    </div>
  );
};

export default Footer;