import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';



const firebaseConfig = {
    apiKey: "AIzaSyCkOYRpT7xh5Nv0PK3Dz1Qp-tHTdPkAkN0",
    authDomain: "dis-prod-2024.firebaseapp.com",
    databaseURL: "https://dis-prod-2024-default-rtdb.firebaseio.com",
    projectId: "dis-prod-2024",
    storageBucket: "dis-prod-2024.appspot.com",
    messagingSenderId: "179772827174",
    appId: "1:179772827174:web:7b4c4f4704a87573a8a8e7",
    measurementId: "G-BJXYNLLGXG"
  };

// Optional: Initialize other Firebase services (e.g., authentication, firestore)
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const realtimeDatabase = getDatabase(app);
