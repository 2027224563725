import React , { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth , signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, setDoc, doc } from 'firebase/firestore';
import './Delete.css';

const Delete = () => {
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
  
    const firebaseConfig = {
        apiKey: "AIzaSyCkOYRpT7xh5Nv0PK3Dz1Qp-tHTdPkAkN0",
        authDomain: "dis-prod-2024.firebaseapp.com",
        databaseURL: "https://dis-prod-2024-default-rtdb.firebaseio.com",
        projectId: "dis-prod-2024",
        storageBucket: "dis-prod-2024.appspot.com",
        messagingSenderId: "179772827174",
        appId: "1:179772827174:web:7b4c4f4704a87573a8a8e7",
        measurementId: "G-BJXYNLLGXG"
      };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
  
    // Function to handle login
    const handleLogin = async () => {
        setIsSigningIn(true);
      try {
        const auth = getAuth(app);
        const result = await signInWithEmailAndPassword(auth,email, password);
        setUser(result.user);
        setErrorMessage('');
      } catch (error) {
        setErrorMessage(error.message);
      }finally {
        setIsSigningIn(false); // Set isSigningIn back to false regardless of sign-in success or failure
      }
    };
  
    // Function to handle account deletion
    const handleDeleteAccount = async () => {
      try {
        const firestore = getFirestore(app);
        var isoDateTime = new Date().toISOString();
        await setDoc(doc(firestore, 'Deletions', user.uid), {
            email: user.email,
            uid : user.uid,
            timestamp: isoDateTime
          });
        setUser(null);
        setErrorMessage('');
        setEmail(''); // Clear email input value
        setPassword(''); // Clear password input value
        alert('Your Account Deletion request has been sent successfully.');
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
  
    return (
        <div className="container">
        {user ? (
          <div className="card">
            <h2>Do you wish to delete your account?</h2>
            <button onClick={handleDeleteAccount}>Confirm Delete</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        ) : (
            <div className="login-form">
            <h2>Login</h2>
            <div className="input-container">
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="input-container">
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            {isSigningIn ? ( // Render "Please wait" text when signing in
            <p>Please wait...</p>
            ) : ( // Render sign-in button otherwise
            <button onClick={handleLogin}>Login</button>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        )}
       </div>
    );
  };
    
  export default Delete;