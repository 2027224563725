import React, { useEffect, useState }  from "react";
import '../Home_Content/Home_Content.css';
import coin from '../../assets/icons/coin.png';
import '../Pricing/Pricing.css';
import { db } from '../../firebase';
import { storage } from "../../firebase";
import { css } from '@emotion/react';
import { ClipLoader } from "react-spinners";
import { collection , getDocs } from "firebase/firestore";
import { getDownloadURL , ref } from "firebase/storage";

const HomeContent = ( { collectionPath , storagePath , heading } ) => {

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const cachedData = localStorage.getItem(`cachedFiles_${collectionPath}`);
        if (cachedData) {
          setFiles(JSON.parse(cachedData));
          setLoading(false);
        } else {
          // Fetch list of files from Firestore
        const filesCollection = await getDocs(collection(db, collectionPath))
        console.log(`File Collection size ${filesCollection.size}`)
        const fileNames = filesCollection.docs.map((doc) => doc.data().file);

        // Fetch logo and description for each file
        const filesWithDetails = await Promise.all(
          fileNames.map(async (fileName) => {
            // Fetch JSON file from Storage
            console.log(`File Name is ${fileName}`)
            const jsonFileUrl = await getDownloadURL(ref(storage,`${storagePath}/${fileName}`))
            console.log(`Download Url ${jsonFileUrl}`)

            const jsonFileResponse = await fetch(jsonFileUrl);
            const jsonFileData = await jsonFileResponse.json();

            // Fetch logo
            const logoResponse = await fetch(jsonFileData.logo);
            console.log(logoResponse)

            // Fetch description
            const descriptionResponse = await fetch(jsonFileData.description);
            const descriptionText = await descriptionResponse.text();

            return {
              ...jsonFileData,
              logo: logoResponse.url,
              description: descriptionText,
            };
          })
        );

        localStorage.setItem(`cachedFiles_${collectionPath}`,JSON.stringify(filesWithDetails))

        setFiles(filesWithDetails);
        }        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();

  },[]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const convertToBulletedList = (paragraph) => {
    const lines = paragraph.split('. '); // Split paragraph into lines at full stops
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index} style={{ marginBottom: '8px' }}>{line}</li>
        ))}
      </ul>
    );
  };

    return(
      <div className= { 'card-container-vertical' }>
        <h1>{heading}</h1>
      {loading ? (
        <div className= {'spinner-container-vertical' }>
          <ClipLoader css={override} size={150} color={'#123abc'} loading={loading} />
        </div>
      ) : (
        files.map((file, index) => (
        <div className={'card-vertical' } key={index}>
          <img src={file.logo} alt={file.title} className="card-logo-vertical" />
          <div className={'card-content-vertical' }>
            <h3 className={'card-title-vertical' }>{file.title}</h3>
            <p className={'card-description-vertical' }>
              {convertToBulletedList(file.description)}</p>
          </div>
        </div>
        ))
      )}
      {heading === "Pricing" && (
      <div className="card-pricing-vertical">
        <div className="card-pricing">
          <img className="card-logo-pricing" src={coin} alt="coin" />
          <div className="card-content-pricing">
            <div className="content-wrapper">
              <div>
                <h2 className="card-title-pricing">Coin Recharge Pricing Range</h2>
                <p className="pricing-range">Rs.6 - Rs.100</p>
                <h3>Services</h3>
                <ul className="services">
                  <li>Respond & Sell</li>
                  <li>Sale & Earn</li>
                  <li>Ride</li>
                  <li>Drive</li>
                </ul>
              </div>
              <div className="description">
                <p>Note : Recharge your account with your desired amount. We recommend recharging up to Rs. 100 for optimal usage, but you can recharge for more if you wish.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
       )}
    </div>
    );
};

export default HomeContent;