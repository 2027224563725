import React from "react";
import './MyRow.css';

const MyRow = ({ imgLink, title, description, index }) => {
    return (
    <div className="row">
      {index % 2 === 1 ? ( // If index is odd, render image first
        <>
          <div className="image-container" style={{ flex: '30%' }}>
            <img src={imgLink} alt="Image" />
          </div>
          <div className="card" style={{ flex: '70%' }}>
            <div className="card-title">{title}</div>
            <div className="card-content">{description}</div>
          </div>
        </>
      ) : ( // If index is even, render card first
        <>
          <div className="card" style={{ flex: '70%' }}>
            <div className="card-title">{title}</div>
            <div className="card-content">{description}</div>
          </div>
          <div className="image-container" style={{ flex: '30%' }}>
            <img src={imgLink} alt="Image" />
          </div>
        </>
      )}
    </div>
    );
};

export default MyRow;